<template>
  <div class="min-h-full">
    <Header />
    <div class="error-404 mt-10">
      <img
        src="@/assets/img/icons/404-error-logo.png"
        width="135"
      >
      <h2 class="error-404__title mt-5 mb-4">
        Oops! This page doesn’t exist.
      </h2>
      <p v-if="isAuth">
        Why don't you head over to your
        <router-link
          class="underline"
          to="/"
        >project overview</router-link>.
      </p>
      <p v-if="!isAuth">
        Why don't you head back to
        <router-link
          class="underline"
          to="/"
        >
          login
        </router-link>.
      </p>
    </div>
    <Footer />
  </div>
</template>


<script>
import auth from '@/auth'
import Header from '@/layouts/Header'
import Footer from '@/layouts/Footer'

export default {
  name: 'AppLayout',
  components: {
      Header,
      Footer,
  },
  data(){
    return{
      isAuth: auth.check(),
    }
  }
}
</script>

<style lang="scss">
  .error-404 {
    padding-top:70px;
    width: 100%;
    height: 100%;
    min-height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title{
      font-weight:bold;
    }
    p{
      font-size:16px;
      a{
        color:#0FCE83!important;
      }
    }
  }
</style>
